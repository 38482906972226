import './App.css';
import{useState} from 'react';
import { useForm } from 'react-hook-form';
import TextInput from "./components/forms/textInput";

function App() {

  const { register, handleSubmit, clearErrors, reset, watch, formState: { errors } } = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange'
  });

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  
  // For password conf
  const passwordWatch = watch("password", "");

  const onSubmit = data => {
    setLoading(true);
    const formData  = new FormData();

    for(const name in data) {
      formData.append(name, data[name]);
    }

    const requestOptions = {
        method: 'POST',
        mode: 'no-cors',
        body: formData
    };

    fetch('https://n8n.above-agency.com/webhook/aps-registration', requestOptions).then(res => {
      reset();
      setSubmitted(true);
      clearErrors();
      setLoading(false);
    })
  }

  return (
    <div className="App">
 <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img
              className="mx-auto h-32 w-auto"
              src="APS Setup form.png"
              alt="Workflow"
            />
            <h2 className="text-center text-2xl mt-6 tracking-widest font-extrabold text-gray-900 font-inter uppercase ">Register</h2>
            <p className="mt-2 text-center text-md text-gray-600">
              Submit the form below to register for Above Privacy Suite.{' '}
            </p>
          </div>
          <form className="mt-8 space-y-2" onSubmit={handleSubmit(onSubmit)}>
    <div>
                      <TextInput
                        instructions="Please input the email that made the original purchase."
                        labelClassName="uppercase tracking-wider font-inter block text-left text-sm font-bold text-gray-700"
                        id="email"
                        label="Customer Email"
                        errors={errors}
                        register={register}
                        validation={{
                          required: "required",
                          maxLength: 50,
                          pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Not a valid email.",
                          },
                        }}
                        errorMessageClassName="text-left text-red-500 text-sm"
                        instructionsClassName="text-left text-gray-500 block text-sm"
                      />
</div>

                    

                <div>
                <TextInput
                  labelClassName="uppercase tracking-wider font-inter block text-left text-sm font-bold text-gray-700"
                  id="username"
                  label="Username"
                  instructions="The username for your above accounts. For instance, if you pick 'anonymous', you will receive the email 'anonymous@above.im'"
                  errors={errors}
                  register={register}
                  validation={{
                    required: "required",
                    maxLength: 50,
                    pattern: {
                      value: /^[0-9A-Za-z.-]+$/,
                      message: "Letters, numbers, periods and hyphens (-) only.",
                    },
                  }}
                  errorMessageClassName="text-left text-red-500 text-sm"
                  instructionsClassName="text-left text-gray-500 block text-sm"
                  />

 
                </div>

                <div>
                <TextInput
                  labelClassName="uppercase tracking-wider font-inter block text-left text-sm font-bold text-gray-700"
                  id="password"
                  label="Password"
                  instructions="Do not forget your password, write it down or keep it in a safe place."
                  errors={errors}
                  register={register}
                  type="password"
                  validation={{
                    required: "required",
                    minLength: 8,
                    maxLength: 64,
                  }}
                  errorMessageClassName="text-left text-red-500 text-sm"
                  instructionsClassName="text-left text-gray-500 block text-sm"
                  />

                </div>

                <div>
                <TextInput
                  labelClassName="uppercase tracking-wider font-inter block text-left text-sm font-bold text-gray-700"
                  id="password_confirm"
                  label="Confirm Password"
          
                  errors={errors}
                  type="password"
                  register={register}
                  validation={{
                    required: true,
                    validate: {
                      matchPassword: (value) =>
                        value === passwordWatch || "The passwords do not match",
                    },
                  }}
                  errorMessageClassName="text-left text-red-500 text-sm"
                  instructionsClassName="text-left text-gray-500 block text-sm"
                  />

                </div>

            <div className="mt-4">
              <button
                type="submit"
                className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-md font-inter tracking-widest uppercase font-bold rounded-md text-white ${!submitted ? "bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" :  "bg-indigo-200"}`}
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  {/* <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" /> */}
                </span>
                {loading ? '' : 'Submit'}{loading && <div class="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
    <span class="visually-hidden"></span>
  </div>}
              </button>
              {submitted && <div className="mt-6 px-4 font-bold text-center">Thank you! Check your email for confirmation. Contact support if you have any issues.</div>}
            </div>
          </form>
        </div>
      </div>
    </>
    </div>
  );
}

export default App;
