import React, { useState } from "react";
import { useForm } from "react-hook-form";
import SimpleText from "../Text/SimpleText";

export default function TextInput({
  errors,
  register,
  validation,
  id,
  label,
  type,
  required,
  instructions,
  labelClassName,
  inputErrorClassName,
  inputClassName,
  errorMessageClassName,
  instructionsClassName,
}) {
  return (
    <>
      <label htmlFor={id} className={labelClassName}>
        {label}
      </label>
      {instructions && (
        <span className={instructionsClassName}>
          {instructions}
        </span>
      )}
      <div className="mt-1">
        <input
          id={id}
          autoComplete={id}
          type={type}
          {...register(id, validation)}
          className={errors[id] ? inputErrorClassName : inputClassName}
        />
      </div>
      <div className="mt-1 h-4">
        {errors[id]?.type === "required" && (
          <span className={errorMessageClassName}>{label} is required...</span>
        )}
        {errors[id]?.type === "minLength" && (
          <span className={errorMessageClassName}>
            {label} should be at least {validation.minLength} characters.
          </span>
        )}
        {errors[id]?.type === "maxLength" && (
          <span className={errorMessageClassName}>
            {label} should be at least {validation.maxLength} characters.
          </span>
        )}
        {errors[id]?.type === "pattern" && (
          <span className={errorMessageClassName}>
            {validation.pattern.message}
          </span>
        )}
        {errors[id]?.type === "matchPassword" && (
          <span className={errorMessageClassName}>
            Passwords don't match.
          </span>
        )}
      </div>
    </>
  );
}

TextInput.defaultProps = {
  label: "",
  type: "text",
  validation: { required: false },
  labelClassName: "block text-sm font-medium text-gray-700",
  inputClassName:
    "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm",
  inputErrorClassName:
    "appearance-none block w-full px-3 py-2 border border-red-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm",
  errorMessageClassName:
    "text-sm text-red-500",
  instructionsClassName:
  "text-sm text-gray-500 text-left justify-start "
  
};
