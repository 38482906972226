import React from "react";

function SimpleText({
  select,
  lined,
  mt,
  ml,
  mb,
  maxWidth,
  width,
  textAlign,
  justify,
  size,
  text,
  lineHeight,
  weight,
  grade,
  color,
}) {
  return (
    <div
      className={`
      mt-${mt} 
      ml-${ml}
      mb-${mb} 
      
      ${
        lined
          ? ` pb-2 border-b border-gray-200 flex justify-${justify}`
          : `flex justify-${justify}`
      } 
      `}
    >
      <p
        className={`
        ${`w-${width}`}
        ${maxWidth && `max-w-${maxWidth}`}}
            select-${select}


            
         text-${textAlign}
          text-${size}
           leading-${lineHeight}
            font-${weight}
            text-${color}${grade && `-${grade}`}`}
      >
        {text}
      </p>
    </div>
  );
}

SimpleText.defaultProps = {
  justify: "center",
  select: "none",
  lined: false,
  mt: "4",
  ml: "2",
  maxWidth: undefined,
  width: "10/12",
  textAlign: "left",
  size: `xl`,
  text: `No text prop`,
  lineHeight: `0`,
  weight: `light`,
  color: `black`,
  grade: ``,
  width: "",
};

export default SimpleText;
